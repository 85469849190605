import initScrollReveal from "./scripts/scrollReveal";
import initTiltEffect from "./scripts/tiltAnimation";
import { targetElements, defaultProps } from "./data/scrollRevealConfig";

initScrollReveal(targetElements, defaultProps);
initTiltEffect();

// Toggle Menu Icon ========================================
let toggleIcon = document.querySelector(".menuIcon");

const toggleChange = () => {
  if (toggleIcon.className != "menuIcon toggle") {
    toggleIcon.className += " toggle";
  } else {
    toggleIcon.className = "menuIcon";
  }
};

toggleIcon.addEventListener("click", toggleChange);

// navbar javacript
document.addEventListener("DOMContentLoaded", function () {
  // Navbar JavaScript
  let menuIcon = document.querySelector(".menuIcon");
  let nav = document.querySelector(".overlay-menu");

  const menuChange = () => {
    if (nav.style.transform !== "translateX(0%)") {
      nav.style.transform = "translateX(0%)";
      nav.style.transition = "transform 0.2s ease-out";
    } else {
      nav.style.transform = "translateX(-100%)";
      nav.style.transition = "transform 0.2s ease-out";
    }
  };

  menuIcon.addEventListener("click", menuChange);

  // Overlay menu JavaScript
  const overlayMenuItems = document.querySelectorAll(".overlay-menu li");

  overlayMenuItems.forEach((item) => {
    item.addEventListener("click", () => {
      menuChange();
      toggleChange();
    });
  });
});
